.update_profile_box {
  width: 800px;
  height: auto;
  background-color: #4A4A4F;
  box-shadow: 0px 0px 2px 0px #00000036;
  border-radius: 10px;
  padding: 20px;
  overflow: auto;
  margin: 20px 0;
  color: white;
}
.update_profile_form {
  width: 80%;
}
