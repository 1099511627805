.ticket_reply_box {
  position: relative;
  width: calc(100% - 50px);
  background-color: #4A4A4F;
  box-shadow: 0px 0px 2px 0px #00000036;
  border-radius: 10px;
  padding: 20px;
  overflow: auto;
  height: calc(100vh - 300px);
  border: 1px solid #a3ec26;
}

.in_message {
  max-width: calc(60% - 20px);
  background-color: #00e38c1f;
  padding: 10px;
  border-radius: 5px;
  font-size: var(--fontSize-xx-small);
  line-height: 20px;
  margin: 5px;
  color: #00482c;
}

.out_message {
  max-width: calc(60% - 20px);
  background-color: #a3ec26;
  color: white;
  padding: 10px;
  border-radius: 5px;
  margin: 5px 0 5px 40%;
  line-height: 20px;
  font-size: var(--fontSize-xx-small);
}

.ticket_message_date {
  font-size: var(--fontSize-xx-small);
  font-weight: var(--font-semiBold);
  text-align: right;
}

.reply_box {
  position: absolute;
  bottom: 10px;
  width: calc(100% - 40px);
}

.reply_textarea {
  width: calc(100% - 20px);
  height: 60px;
  outline: none;
  border: none;
  font-size: var(--fontSize-x-small);
  resize: none;
}

.ticket_reply_Messages {
  height: calc(100% - 130px);
  overflow: auto;
}

.ticket_reply_all_Messages {
  height: 100%;
  overflow: auto;
}

.ticket_reply_Messages::-webkit-scrollbar,
.ticket_reply_all_Messages::-webkit-scrollbar {
  display: none;
}

.close_ticket_message {
  display: flex;
  gap: 10px;
  align-items: center;
}

.close_ticket_message input {
  width: 15px;
  height: 15px;
}

.close_ticket_message p {
  font-size: var(--fontSize-xx-small);
  font-weight: var(--font-medium);
  color: white;
}

.reply_box_container {
  border: 1px solid var(--grey-color);
  border-radius: 5px;
  padding: 5px 10px;
  margin-bottom: 10px;
}

.ticket_error {
  color: red;
}
