/* @import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap"); */

:root {
  --fontFamily-serif: Playfair Display;
  --fontFamily-sansSerif: Inter;

  --font-regular: 400;
  --font-medium: 500;
  --font-semiBold: 600;
  --font-bold: 700;
  --font-extraBold: 800;
  --font-black: 900;

  --fontSize-xx-small: 0.9rem;
  --fontSize-x-small: 1rem;
  --fontSize-small: 1.2rem;
  --fontSize-regular: 1.4rem;
  --fontSize-medium: 1.6rem;
  --fontSize-large: 2rem;
  --fontSize-x-large: 2.3rem;
  --fontSize-xx-large: 2.5rem;
}
