.create_new_ticket_box {
  width: 700px;
}

.create_new_ticket_row {
  margin: 0 0 1.5rem 0;
}

@media (max-width: 767px) {
  .create_new_ticket_box {
    width: 100%;
  }
}
