.notification_read {
  background-color: #a3ec26;
  padding: 20px;
  width: 300px;
  box-shadow: 0px 0px 2px 0px #00000036;
  border-radius: 7px;
  cursor: pointer;
  margin: 5px 0;
  color: white;
}

.notification_unread {
  background-color: #a3ec26;
  color: white;
  /* background-color: var(--primary-color); */
  padding: 20px;
  width: 300px;
  box-shadow: 0px 0px 2px 0px #00000036;
  border-radius: 7px;
  cursor: pointer;
  margin: 5px 0;
  /* color: #fff; */
}

.notification_date_time {
  font-size: var(--fontSize-xx-small);
  font-weight: var(--font-medium);
  text-align: right;
}

.notification_box {
  display: flex;
  gap: 40px;
}

.notification_list_box {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 400px;
  background-color: #373737;
  border-radius: 7px;
  box-shadow: 0px 0px 2px 0px #00000036;
  padding-bottom: 20px;
  color: white;
}

.notification_heading {
  display: flex;
  justify-content: space-between;
  font-size: var(--fontSize-x-small);
  font-weight: var(--font-medium);
  margin-bottom: 2px;
}

.notification_heading span:nth-child(2) {
  font-size: 10px;
}

.notification_paragraph {
  font-size: var(--fontSize-xx-small);
  font-weight: var(--font-regular);
  font-weight: var(--font-medium);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--grey-color);
}

.notification_read .notification_paragraph {
  color: black;
}

.notification_active {
  background-color: rgb(50, 103, 50);
  color: #fff;
}

.notification_active .notification_paragraph {
  color: #fff;
}

.notification_heading_row {
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin: 20px 0;
}

.notification_heading_row P:nth-child(1) {
  font-size: var(--fontSize-x-small);
  font-weight: var(--font-medium);
}

.notification_heading_row P:nth-child(2) {
  font-size: var(--fontSize-xx-small);
  font-weight: var(--font-medium);
  color: var(--primary-color);
}

.notification_count {
  margin-left: 10px;
  font-size: var(--fontSize-x-small);
}

.notification_description_box {
  width: calc(90% - 450px);
  background-color: #fff;
  box-shadow: 2px 2px 5px 1px #0000001f;
  height: 100px;
  border-radius: 7px;
  padding: 20px;
}

.notification_description_box p:nth-child(1) {
  font-size: var(--fontSize-x-small);
  font-weight: var(--font-medium);
}

.notification_description_box p:nth-child(2) {
  font-size: var(--fontSize-xx-small);
  font-weight: var(--font-regular);
  color: var(--light-grey-color);
  margin-top: 10px;
}

.clearall_noti_btn {
  background-color: #a3ec26;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 5px;
  width: 7vw;
  height: 4vh;
}
