.butn_redeem{
    background-color: #a3ec26;
    color: white;
    border-radius: 5px;
    padding: 5px;
    cursor: pointer;
}

.earning_images {
    width: 4vw;
    margin-left: 8px;
  }
  
  .escrow_images {
    width: 4vw;
    height: 8vh;
    margin-left: 8px;
  }
  
  .final_images {
    width: 4vw;
    height: 9vh;
    margin-left: 8px;
  }