.sidebar_bg {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: black;
  width: 200px;
  padding: 10px;
  height: 100%;
  overflow: auto;
  box-shadow: 0 0 10px 0px hsla(0, 0%, 0%, 0.102);
  transition: all 0.3s ease-in-out;
}



.sidebar_header span {
  font-size: 0.6rem;
  color: #b9bbbd;
}

.sidebar_item_box {
  display: flex;
  align-items: center;
  gap: 15px;
  cursor: pointer;
  width: 170px !important;
  height: 35px !important;
  padding: 5px;
  margin: 5px 0;
}

.sidebar_icon {
  width: 20px;
  height: 20px;
  color: #a3ec26;
}

.sidebar_item_box:hover {
  box-shadow: 0 0 5px 0 #0000002c;
  background-color: white;
}

.sidebar_item_box p {
  font-size: 0.9rem;
  color: var(--grey-color);
  /* text-align: center; */
  text-transform: capitalize;
}

.active_border {
  padding: 3px;
  background-color: #a3ec26;
  border-radius: 5px;
  height: 40px;
}

.trading_logo {
  width: 10vw;
  height: 16vh;
}


@media (max-width: 550px) {
  .sidebar_bg {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 180px;
    background-color: black;
    transition: transform 0.3s ease-in-out;
    overflow-y: auto;
    transform: translateX(-100%);
    z-index: 1000;
  }

  .sidebar_bg.open {
    transform: translateX(0);
  }

  .toggle-btn {
    display: block;
    position: fixed;
    top: 10px;
    left: 10px;
    z-index: 1001;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: #a3ec26;
  }

  .overlay {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
  }
  .trading_logo{
    width: 20vw;
    height: 20vw;
  }
}