.web_button {
  padding: 0.5rem 1rem;
  border-radius: 4px;
  font-size: var(--font-small);
  font-weight: var(--font-medium);

  cursor: pointer;
  border: 2px solid var(--tertiary-color);
  color: var(--tertiary-color);
}

.package_button {
  width: 110px;
  height: 30px;
  background-color: #a3ec26;
  font-size: var(--fontSize-xx-small);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 0;
  padding: 0;
}

.submit_btn {
  min-width: 100px;
  background-color: #a3ec26;
  font-size: var(--fontSize-x-small);
  color: white;
  border: none;
  border-radius: 5px;
  min-height: 40px;
  cursor: pointer;
  margin: 0;
}

.refresh_btn {
  font-weight: var(--font-medium);
  font-size: var(--fontSize-xx-small);
  background-color: #a3ec26;
  border: none;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 5px;
}

.support_btn {
  font-weight: var(--font-medium);
  font-size: var(--fontSize-xx-small);
  background-color: #a3ec26;
  border: none;
  color: white;
  height: 30px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.widthdraw_btn {
  font-weight: bold;
  cursor: pointer;
  border-radius: 5px;
  font-size: 14px;
  /* background-color: var(--primary-color); */
  border: none;
  background-color: white;
  color: rgb(0, 183, 74);
  /* color: white; */
  height: 40px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

@media (max-width: 576px) {
  .web_button {
    padding: 0 10px;
  }
  .refresh_btn {
    height: 35px;
  }
  .widthdraw_btn {
    height: 30px;
  }
}
