.earning_wallet_header_row {
  display: flex;
  align-items: center;
  gap: 20px;
}

.earning_wallet_header_col {
  width: 12vw;
  height: 40vh;
  text-align: center;
  background-color: #313135;
  padding: 10px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3rem;
}

.earning_wallet_header_col_division_line {
  display: block;
  width: 1.5px;
  height: 60px;
  background-color: #ccc;
  border-radius: 1px;
}

.earning_wallet_header_col p:nth-child(1) {
  color: #a3ec26;
}

.earning_wallet_header_col p:nth-child(2) {
  color: #ffffff;
}

.earning_wallet_description {
  font-size: 10px;
  color: var(--grey-color);
  margin: 10px 0;
}

.earning_wallet_description span {
  color: var(--link-color);
  padding-right: 5px;
  font-weight: var(--font-medium);
}

.withdraw_modal_bg {
  width: 400px;
  padding: 20px;
  display: grid;
  gap: 10px;
}

.widthdrawl_btnnn_earningwallet {
  margin-top: 1rem;
  margin-left: 5px;
}

.withdraw_icon_div {
  width: 6vw;
  height: 12vh;
  border-radius: 5px;
  background-color: #4a4a4f;
  margin-top: 1rem;
  padding: 5px;
}

.earning_images {
  /* width: 4vw; */
  margin-left: 8px;
}

.escrow_images {
  /* width: 4vw; */
  /* height: 8vh; */
  margin-left: 8px;
}

.final_images {
  /* width: 4vw;
  height: 9vh; */
  margin-left: 8px;
}


@media (max-width: 576px) {
  /* Styles for mobile */
  .earning_wallet_header_row {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-left: 2rem;
  }

  .earning_wallet_header_col {
    /* margin-bottom: 20px; */
    width: 20vw;
    height: 25vh;
  }

  .widthdrawl_btnnn_earningwallet {
    display: none;
  }

  .withdraw_btn_on_mobile {
    margin-top: 2rem;
    cursor: pointer;
    /* margin-left: 7rem; */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .escrow_images{
    width: 30px;
    height: 40px;
  }

  .earning_wallet_header_row p {
    text-align: center;
  }
  .withdraw_icon_div{
    width: 18vw;
    height: 7vh;
  }

  .widthdraw_btn{
    background-color: #a3ec26;
    color: white;
    cursor: pointer;

  }
  .main_responive{
    display: flex;
    flex-direction: column;
  }
}