.kyc_accept_box {
  width: 80%;
  height: 40px;
  /* background-color: #00e38c3a; */
  color: white;
  border: 2px solid #a3ec26;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  font-size: var(--fontSize-regular);
  font-weight: var(--font-medium);
  border-radius: 10px;
}
