.open_tickets {
  margin: 1rem 0 2rem 0;
}
.open_tickets_head {
  font-size: var(--fontSize-x-small);
  font-weight: var(--font-semiBold);
  text-transform: uppercase;
  color: var(--grey-color);
  letter-spacing: 1px;
  margin-bottom: 2rem;
}

.closed_tickets {
  margin: 2rem 0;
}

.closed_tickets_head {
  font-size: var(--fontSize-x-small);
  font-weight: var(--font-semiBold);
  text-transform: uppercase;
  color: var(--primary-color);
  letter-spacing: 1px;
  margin-bottom: 2rem;
}

.closed_tickets_row td {
  color: var(--primary-color) !important;
}

.ticket_header_row {
  display: flex;
  justify-content: space-between;
}
