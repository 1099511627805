.rewards_bg {
  width: 100%;
}

.rewards_heading {
  font-size: var(--fontSize-x-small);
  font-weight: var(--font-semiBold);
  text-transform: uppercase;
  color: var(--grey-color);
  letter-spacing: 1px;
  margin: 1rem 0;
}
