.input_parent_div {
  width: 100%;
}
.label_style {
  display: inline-block;
  padding-bottom: 10px;
  padding-left: 3px;
  width: 100%;
  color: white;
}

.input_wrapper {
  border: 1px solid rgb(155, 155, 155);
  border-radius: 5px;
  display: flex;
  align-items: center !important;
  height: 40px;
  width: 100%;
  background-color: var(--white-color);
}
.add_input {
  width: 100%;
  padding: 0 5px;
  border: none;
  outline: none;
  position: relative;
  font-size: var(--fontSize-x-small);
  height: 35px;
  background-color: transparent !important;
  color: white;
}
.input_eye_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: #353535;
  cursor: pointer;
  margin-right: 5px;
}

.input_error {
  color: rgb(241, 1, 1);
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.5px;
}

.image_input {
  width: 100%;
  border-radius: 5px;
  padding: 0 5px;
  border: none;
  outline: none;
  position: relative;
  font-size: var(--fontSize-x-small);
}

.input_message {
  font-size: var(--fontSize-xx-small);
  letter-spacing: 0.5px;
  padding: 2px 0 10px 0;
}
