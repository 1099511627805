.profile_box {
  width: 80%;
  background-color: #4A4A4F;
  box-shadow: 0px 0px 2px 0px #00000036;
  border-radius: 10px;
  padding: 20px;
  overflow: auto;
}

.profile_heading_border {
  height: 2px;
  width: 100%;
  background-image: linear-gradient(
    to right,
    black 60%,
    rgba(255, 255, 255, 0) 0%
  );
  background-position: bottom;
  background-size: 20px 2px;
  background-repeat: repeat-x;
}

.profile_heading {
  font-weight: var(--font-semiBold);
  font-size: var(--fontSize-x-small);
  color: white;
  padding-bottom: 10px;
}

.input_field_two {
  position: relative;
  width: 100%;
}

.input_field_two span {
  position: absolute;
  top: -22px;
  left: 10px;
  font-size: 15px;
  font-weight: 500;
  background-color:#4A4A4F;
  color: white;
  padding: 0px 5px;
}

.input_field_two input {
  border: 1px solid rgb(155, 155, 155);
  border-radius: 5px;
  display: flex;
  align-items: center !important;
  height: 25px;
  width: calc(100% - 20px);
  background-color: var(--white-color);
  color: white;
  padding: 10px;
  outline: none;
  font-size: var(--fontSize-x-small);
}

.profile_inputs_wrapper {
}

.profile_inputs_row {
  display: flex;
  justify-content: space-between;
  margin: 2rem 0;
  gap: 50px;
}
