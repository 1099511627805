.verify_otp_bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.153);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1111;
}

.verify_otp_box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 20px;
  box-shadow: 0 0 2px 1px #00000022;
  padding: 30px;
}

.close_btn {
  text-align: right;
  width: 100%;
  cursor: pointer;
}

.verify_otp_bg form {
  display: flex;
  flex-direction: row !important;
  justify-content: center !important;
  align-items: center !important;
}

.verify_otp_heading {
  font-size: 1.1rem;
  text-align: center;
  margin: 1rem 0;
  font-weight: 500;
}

.otp_time {
  font-weight: 500;
  font-size: var(--font-small);
  margin: 1rem 0;
}

.otp_receive_msg {
  font-size: var(--font-xsmall);
  margin: 1rem 0;
}

.otp_receive_msg span {
  color: var(--link-color);
  cursor: pointer;
}

.otp_input {
  display: flex !important;
  flex-direction: row !important;
  justify-content: center;
  width: 100%;
}

.otp_input input {
  width: 45px;
  height: 50px;
  margin: 5px;
  border-radius: 5px;
  border: none;
  outline: none;
  box-shadow: rgb(128, 127, 126) 0px 2px 3px 1px;
  text-align: center;
  font-size: var(--font-small);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
  display: none;
}

.verify_otp_err {
  color: rgb(211, 7, 7);
  font-size: var(--font-xsmall);
  text-align: right;
  width: 95%;
}
