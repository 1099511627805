.navbar_bg {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* width: calc(100% - 80px); */
  height: 50px;
  padding: 10px 40px 0 40px;
  z-index: -1;
}

.navbar_menu_icon {
  color: black;
  font-size: 35px;
  cursor: pointer;
}

.navbar_trading_logo {
  display: block;
  font-size: var(--fontSize-regular);
  margin-bottom: 10px;
}

.navbar_left {
  display: flex;
  align-items: center;
  gap: 20px;
}

.navbar_right {
  display: flex;
  align-items: center;
  gap: 20px;
}

.button_icon_text {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 0 10px;
}

.user_profile_icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  position: relative;
  border-radius: 25px;
}

.navbar_user_name {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 14px;
  gap: 20px;
  cursor: pointer;
  text-transform: capitalize;
  color: #a3ec26;
}

.navbar_left_items {
  background-color: red;
}

.notification_count {
  position: absolute;
  top: -9px;
  left: 7.5px;
  background-color: rgb(196, 16, 16);
  color: white;
  border-radius: 50%;
  padding: 1px 6px;
}

@media (max-width: 576px) {
  .navbar_bg {
    width: calc(100% - 20px);
    height: 50px;
    padding: 10px 10px 0 10px;
  }
  .user_profile_icon {
    width: 40px;
    height: 40px;
  }

  .navbar_right {
    gap: 10px;
  }
}

.profile_pop_bg {
  margin-top: 10px;
  position: absolute;
  right: 20px;
  display: grid;
  background-color: rgb(255, 255, 255);
  border: 3px solid var(--primary-color);
  border-radius: 15px;
  box-shadow: 2px 2px 5px 2px rgba(0, 0, 0, 0.2);
  z-index: 1111;
  padding: 10px;
}

.profile_pop_bg p {
  font-size: var(--fontSize-x-small);
  display: flex;
  align-items: center;
  gap: 20px;
  margin: 20px 0;
  cursor: pointer;
}
