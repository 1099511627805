:root {
  --primary-color: #00e38c;
  --secondary-color: #1f64ff;
  --tertiary-color: #11193c;

  --light-color: #f5f2fc;
  --link-color: #189be7;

  --grey-color: #4f5666;
  --light-grey-color: #828fad;
  --extra-light-grey-color: #b5bad0;
}
