.page_path {
  padding: 0 40px;
  /* margin-top: -30px; */
}

.active_page_head {
  font-size: var(--fontSize-large);
  font-weight: 600;
  text-transform: capitalize;
}

.active_page_path {
  font-size: 12px;
  font-weight: 500;
  background-color: var(--white-color);
  text-transform: capitalize;
}

.active_page_path {
  color: var(--extra-light-grey-color);
}

@media (max-width: 768px) {
  .page_path {
    padding: 0 10px;
    height: 50px;
  }
  .active_page_head {
    font-size: var(--fontSize-small);
  }
  .active_page_path {
    padding: 5px 0 0 0;
  }
}
