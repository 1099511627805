.add_coin {
  /* color: white; */
}

.balance_history {
  border: 1px solid;
  background-color: #a3ec26;
  color: white;
  border: none;
  border-radius: 6px;

}
.balance_history h4 {
  padding: 6px;
}

.add_coin input {
  display: flex;
  width: 30vw;
  height: 5vh;
  margin-top: 0.5rem;
}

.add_coin label{
    color: white;
}
.deposited_coin{
    color: white;
}
.add_coin p {
  margin-top: 0.5rem;
}

.deposited_coin {
  /* color: white; */
  margin-top: 1rem;
}

.deposited_coin input {
  display: flex;
  width: 30vw;
  height: 5vh;
  margin-top: 0.5rem;
}

.bottom_inputs {
  /* color: white; */
  margin-top: 5rem;
}

.bottom_inputs input {
  display: flex;
  width: 30vw;
  height: 5vh;
}

.bottom_inputs label {
  color: white;
}

.submit_coin_btn {
  margin-top: 2rem;
  width: 10vw;
  height: 6vh;
  background-color: #a3ec26;
  color: white;
  border-radius: 5px;
  cursor: pointer;
}

.main-content{
    display: flex;
    justify-content: space-between;
}

.balance-history-container {
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-end;
  }
  
  .balance_history {
    text-align: right;
  }
  
  .balance_history h4 {
    margin: 0;
    font-size: 1.2rem;
  }
  
  .balance_history span {
    font-weight: bold;
    margin-left: 10px;
  }

/* Mobile styles */
@media (max-width: 550px) {
  .st-token-container.mobile .main-content {
    flex-direction: column;
  }

  .st-token-container.mobile .refral-links.mobile {
    margin-left: 0;
    margin-top: 2rem;
  }

  .st-token-container.mobile .balance_history h4 {
    font-size: 16px;
  }

  .st-token-container.mobile .add_coin,
  .st-token-container.mobile .deposited_coin,
  .st-token-container.mobile .bottom_inputs > div {
    margin-bottom: 1rem;
  }

  .st-token-container.mobile input {
    width: 100%;
  }

  .st-token-container.mobile .submit_coin_btn {
        width: 50vw;
        height: 5vh;
        margin-left: 6rem;
  }

  .balance-history-container {
    justify-content: center;
  }

  .balance_history {
    text-align: center;
  }

  .balance_history h4 {
    font-size: 1rem;
  }
}
 
