.login_bg {
  min-height: 100vh;
  width: 100%;
  overflow: hidden;
  background-image: url("../../assests/images/SIGN-UP.png");
  background-size: contain;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login_form_bg {
  background-color: #373737;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  height: 410px;
  width: 440px;
  border-radius: 10px;
  margin-right: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "Rajdhani";
  font-weight: 700;
}

.login_inputs {
  width: 80%;
  margin: 20px 0;
  font-family: "Rajdhani";
  font-weight: 400;
  font-style: normal;
}

.login_signup_heading {
  font-size: 3rem;
  font-weight: var(--font-bold);
  color: white;
   font-family: "Rajdhani"
}

.login_signUp_bottom_msg {

  margin-top: 20px;
  color: white;
  font-weight: 200;
}

.login_signUp_bottom_msg span {
  color: #a3ec26;
  cursor: pointer;
  font-weight: 700;
}

.forget_password_line {
  width: 80%;
  margin-top: -20px;
  margin-bottom: 10px;
  padding: 0;
  text-align: right; 
  font-size: var(--fontSize-x-small);
  font-weight: var(--font-regular);
  color: var(--grey-color);
}

.forget_password_line span {
  cursor: pointer;
  color: #a3ec26;
}



@media (max-width: 768px) {
  .login_bg {
    padding: 1rem;
  }

  .loginx_box {
    padding: 1rem;
    box-shadow: none;
    border-radius: 0;

  }

  .login_signup_heading {
    font-size: 20px;
  }

  .login_inputs {
    gap: 0.75rem;
  }

  .submit_btn {
    padding: 0.5rem;
  }

  .login_form_bg{
    width: 380px;
    height: 380px;
    margin-left: 1.5rem;
    margin-bottom: 15rem;
  
  }
}