.multi_image_input__parent_div {
  width: 100%;
}

.multi_image_input__wrapper {
  border: 1px solid rgb(155, 155, 155);
  border-radius: 5px;
  display: flex;
  align-items: center !important;
  height: 40px;
  width: 100%;
}

.multi_image_input_ {
  border-radius: 5px;
  padding: 0 5px;
  border: none;
  outline: none;
  position: relative;
  font-size: var(--fontSize-x-small);
}

.multi_file_name {
  margin: 0 5px;
  padding: 5px;
  background-color: #efefef;
  border-radius: 4px;
  display: flex;
  gap: 10px;
  align-items: center;
  font-size: var(--fontSize-xx-small);
}

.multi_file_name span {
  display: flex;
  cursor: pointer;
}

.multi_image_input__message {
  font-size: var(--fontSize-xx-small);
  letter-spacing: 0.5px;
  padding: 2px 0 10px 0;
}

.multi_image_input_error {
  color: rgb(241, 1, 1);
  font-size: var(--fontSize-xx-small);
  letter-spacing: 0.5px;
  padding: 2px 0 10px 0;
  font-weight: var(--font-medium);
}
