.textarea_parent_div {
  width: 100%;
}
.label_style {
  display: inline-block;
  padding-bottom: 10px;
  padding-left: 3px;
}

.textarea_wrapper {
  border: 1px solid rgb(155, 155, 155);
  border-radius: 5px;
  display: flex;
  align-items: center !important;
  width: 100%;
  height: auto;
  background-color: var(--white-color);
}
.textarea_field {
  width: 100%;
  padding: 10px;
  border: none;
  outline: none;
  position: relative;
  font-size: var(--font-small);
  height: 100px;
  background-color: transparent;
  color: white;
}

.textarea_error {
  color: rgb(241, 1, 1);
  font-size: var(--font-xxsmall);
  font-weight: 500;
  letter-spacing: 0.5px;
}
