.change_password_box {
  width: 800px;
  height: auto;
  background-color: #4A4A4F;
  box-shadow: 0px 0px 2px 0px #00000036;
  border-radius: 10px;
  padding: 20px;
  overflow: auto;
  color: white;
}

.change_password_input_box {
  width: 80%;
  color: white;
}

.change_password_box .input_parent_div {
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .layout_children_bg {
      width: calc(100% - 20px);
      padding: 10px;
  }
}
