.layout_bg {
  display: flex;
  width: 100vw;
  height: 100vh;
  padding: 0;
  margin: 0;
  overflow: hidden;
}

.layout_left {
  z-index: 1111;
}

.layout_right {
  width: calc(100% - 200px) !important;
  background-color: #19191a;
}

.layout_children_bg {
  width: calc(100% - 80px);
  padding: 20px 40px;
  overflow: auto;
  height: calc(100vh - 170px);
}

/* .common_refral_component {
  background-color: #202022;
  width: 30vw;
  /* height: 50vh; */
  /* margin-top: 5rem;
  border-radius: 5px;
  margin-right: 15px; */

@media (max-width: 768px) {
  .layout_right {
    width: 100% !important;
  }

  .layout_children_bg {
    width: calc(100% - 20px);
    padding: 10px;
  }
}
