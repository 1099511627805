/* @tailwind base;
@tailwind components;
@tailwind utilities; */

.web_table_box {
  width: calc(100% - 40px);
  background-color: #202022 !important;
  border-radius: 10px;
  padding: 0 20px 10px 20px;
  overflow: auto;
  margin: 20px auto;
}
/* input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #373737 inset !important;
} */

.web_table {
  max-width: max-content;
  min-width: 100%;
  white-space: nowrap;
  border-collapse: collapse;
}
.web_table thead {
  position: sticky;
  top: 0;
  height: 75px;
  background-color: #202022 !important;
}

.web_table thead tr td {
  position: sticky;
  top: 0;
  color: #ffffff;
  font-size: 14 px;
  font-weight: var(--font-medium);
  padding: 15px;
  text-align: center;
  border-bottom: 4px solid rgba(245, 245, 245, 0.558);
}

.web_table tbody tr td {
  font-size: var(--fontSize-xx-small);
  font-weight: var(--font-medium);
  color: #ffffff;
  padding: 15px;
  text-align: center;
}

.web_table_green_td {
  color: #ffffff !important;
}

.success_status {
  color: #a3ec26;
  font-size: var(--fontSize-xx-small);
  font-weight: var(--font-medium);
  text-align: center;
}

.pending_status {
  color: rgb(236, 147, 114);
  font-size: var(--fontSize-xx-small);
  font-weight: var(--font-medium);
  text-align: center;
}

.rejected_status {
  color: red;
  font-size: var(--fontSize-xx-small);
  font-weight: var(--font-medium);
  text-align: center;
}

.web_copyright_line {
  display: block;
  text-align: center;
  position: absolute;
  bottom: 10px;
  padding-left: 30px;
  font-size: var(--fontSize-xx-small);
  /* color: gray; */
}

.web_text_td_para {
  width: 400px !important;
  overflow-wrap: break-word !important;
  word-wrap: break-word !important;
  word-break: break-word !important;
  text-wrap: wrap;
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.overflow-wrap-anywhere {
  overflow-wrap: anywhere;
}

.error {
  color: red;
  font-size: 13px;
  margin-top: 5px;
  margin-bottom: 0;
  text-align: left;
}

.pagination-buttons-container {
  display: flex;
  justify-content: space-between;
  align-self: center;
  padding: 0 10px;
  margin-top: 10px;
  background-color: #ffffff73;
  height: 5vh;
  border-radius: 5px;
}

.pagination-button {
  background-color: #a3ec26;
  color: white;
  /* padding: 3px 6px; */
  width: 6vw;
  height: 4vh;
  border: none;
  border-radius: 0;
  margin: 0px 3px;
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;
  border-radius: 7px;
}

.pagination-button:hover {
  transform: scale(1.1);
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.2);
}

.pagination-number {
  font-size: 1.3rem;
  font-weight: 500;
  color: var(--primary-color);
  transition: color 0.2s, transform 0.2s;
  margin: 0 5px;
}

.pagination-number:hover {
  transform: scale(1.1);
}

.pagination-button:disabled {
  background-color: #a3ec26;
  cursor: not-allowed;
  border-radius: 5px;
}

.green_gradient_button {
  font-weight: bold;
  cursor: pointer;
  border-radius: 5px;
  font-size: 14px;
  border: none;
  color: white;
  height: 40px;
  background-color: #a3ec26;
  box-shadow: 0 1px 8px 2px #00000013;
}

.green_gradient_button:hover {
  scale: 1.1;
  transition: 0.3s;
}

.green_gradient {
  background-color: #313135;
}
.green_gradient_color {
  color: rgb(110, 196, 208);
  color: linear-gradient(
    91deg,
    rgb(110, 196, 208) 18%,
    rgba(0, 255, 52, 1) 100%
  );
}

.font {
  font-family: "rajdhani";
}
* {
  font-family: "rajdhani";
}

/* @tailwind base;
@tailwind components;
@tailwind utilities; */
/* .slick-slide > div {
  margin-right: 30px !important;
} */
