.all_selects{
    display: flex;
    gap: 10px;
   
}
.main_container{
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
}

.all_details{
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* height: 13vh; */
    background-color: #4A4A4F;
    width: 40vw;
    border-radius: 10px;
    padding: 15px;
    
}
.invoice_detail{
    color: #a3ec26;
}

.status_details{
    color: #a3ec26;
    font-weight: 600;
}
.amount_detail{
    color: white;
}



/* Mobile-specific styles */
@media screen and (max-width: 767px) {
    .payment-history-container {
      padding: 1rem;
    }
  
    .all_selects {
      display: flex;
      /* flex-direction: column; */
      gap: 1rem;
      margin-bottom: 1rem;
    }
  
    .all_selects select {
      width: 100% !important;
      height: 40px !important;
    }
  
    .content-wrapper {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
  
    .table-container {
      width: 100%;
      overflow-x: auto;
    }
  
    .web_table {
      font-size: 14px;
      min-width: 600px; /* Adjust based on your table content */
    }
  
    .refrals-container {
      width: 100%;
      margin-top: 1rem;
    }
  }