.dropdown_parent_div {
  width: 100%;
}

.dropdown_wrapper {
  border: 1px solid rgb(155, 155, 155);
  border-radius: 8px;
  display: flex;
  align-items: center !important;
  height: 40px;
  width: 100%;
  background-color: var(--white-color);
}
.web_dropdown {
  width: 100%;
  border-radius: 5px;
  padding: 0 5px;
  border: none;
  outline: none;
  position: relative;
  font-size: var(--font-small);
  height: 35px;
  background-color: transparent;
}

.dropdown_error {
  color: rgb(241, 1, 1);
  font-size: var(--font-xxsmall);
  font-weight: 500;
  letter-spacing: 0.5px;
}
