.crypto_wallet_card {
  background-color: #202022;
  box-shadow: 2px 2px 5px 1px #00000038;
  border-radius: 10px;
  width: 220px;
  height: 220px;
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 10px 0;
}

.crypto_wallet_card_name {
  color: #737373;
  font-size: var(--fontSize-small);
  font-weight: var(--font-semiBold);
}

.crypto_wallet_card_wallet {
  color: #a3ec26;
  font-size: var(--fontSize-x-small);
  font-weight: var(--font-medium);
}

.crypto_wallet_card_amount {
  color: #a3ec26;
  font-size: var(--fontSize-medium);
  font-weight: var(--font-semiBold);
}

.crypto_wallet_card_balace {
  color: #737373;
  font-size: var(--fontSize-x-small);
  font-weight: var(--font-semiBold);
}

.bitt_coin {
  width: 9vw;
  height: 12.5vh;
}

.withdra_btn {
  background-color: #a3ec26;
  color: #fff;
  border-radius: 5px;
  width: 7vw;
  height: 5vh;
  cursor: pointer;
  border: none;
}

.logo_coin {
  background-color: #202022;
  border-radius: 10px;
  width: 28evw;
}
.logo_bitcoin {
  width: 15vw;
  height: 40vh;
  margin-left: 7rem;
}

.st_wallet_responsive {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 550px) {
  .st_wallet_responsive {
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .crypto_wallet_card {
    margin-left: 6rem;
    height: 25vh;
  }
  .bitt_coin {
    width: 35vw;
    height: 10vh;
  }
  .withdra_btn {
    width: 25vw;
    height: 4vh;
  }
  .logo_bitcoin{
    width: 50vw;
    height: 30vh;

  }
}
