.contracts_header_row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.active_contracts_head {
  color: #a3ec26;
  font-size: var(--fontSize-x-small);
  font-weight: var(--font-semiBold);
}

.earning_table {
  margin-top: 20px;
}

.earning_wallet_description {
  font-size: var(--fontSize-x-small);
  color: var(--grey-color);
  margin: 20px 0;
}

.earning_wallet_description span {
  color: var(--link-color);
  padding-right: 5px;
  font-weight: var(--font-medium);
}

.contract_details {
  margin-top: 20px;

  padding: 20px;
  box-shadow: 0 2px 4px 1px #00000025 !important;
  border-radius: 10px;
}

.contract_details td {
  margin-bottom: 10px !important;
  border: none !important;
}

.contract_details div {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.contract_details div p {
  width: 33.3%;
  padding: 10px;
  border-bottom: 1px solid var(--light-grey-color);
}

.contract_details div:nth-child(2) p {
  color: var(--light-grey-color);
}

.contract_details_head {
  color: #a3ec26;
  font-size: var(--fontSize-x-small);
  text-transform: uppercase;
  font-weight: var(--font-semiBold);
  padding-bottom: 10px;
  padding-left: 20px;
}

.contract_details_grand_total {
  color: var(--grey-color);
  font-size: var(--fontSize-xx-small);
  text-transform: uppercase;
  font-weight: var(--font-semiBold);
  padding-bottom: 10px;
  padding-left: 20px;
}

@media (max-width: 768px) {
  .earning_wallet_description {
    font-size: var(--fontSize-xx-small);
  }
}
