.contracts_bg {
  width: 100%;
}

.contracts_header_row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;


}

.active_contracts_head {
  font-size: var(--fontSize-x-small);
  font-weight: var(--font-semiBold);
}

.earning_wallet_description {
  font-size: var(--fontSize-x-small);
  color: var(--grey-color);
  margin: 20px 0;
}

.earning_wallet_description span {
  color: var(--link-color);
  padding-right: 5px;
  font-weight: var(--font-medium);
}

@media (max-width: 768px) {
  .earning_wallet_description {
    font-size: var(--fontSize-xx-small);
  }

  .contracts_content {
    flex-direction: row;
  }

  .refral-links-container {
    margin-left: 20px;
    margin-top: 2rem;
    width: 300px; /* Adjust as needed */
  }
 
  
}

@media screen and (min-width: 768px) {
  .small-screen-only {
    display: none;
  }
}