.registration_bg {
  min-height: 100vh;
  width: 100%;
  overflow: hidden;
  background-image: url("../../assests//images//sign\ up\ new.png");
  background-size: contain;
  background-position: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

.registration_form_bg {
  background-color: #373737;
  /* width: 800px; */
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px 50px;
  margin-right: 4rem;
  /* margin: 50px; */
}

.registration_two_inputs_row {
  width: 100%;
  display: flex;
  gap: 50px;
}
