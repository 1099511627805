.kyc_documentation_bg {
  width: 100%;
}

.kyc_documentation_form_box {
  width: 90%;
  background-color: #4A4A4F;
  box-shadow: 0px 0px 2px 0px #00000036;
  border-radius: 10px;
  margin-top: 10px;
  padding: 20px;
}
