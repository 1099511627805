.coin_images {
  display: flex;
  flex-direction: column;
  width: 350px;
  margin-top: 1rem;
}

.dashboard_chart {
  width: 700px;
  height: 275px;
  margin-top: 4rem;
}

.graph_refral {
  display: flex;
  justify-content: space-between;
  gap: 5rem;
}

.image_coin {
  width: 18vw;
  height: 40vh;
}

.coin_image {
  display: flex;
  justify-content: center;
}

.refral_link_new_ui {
  display: flex;
  justify-content: space-between;
}

.bit_coin_img {
  width: 8.5vw;
  height: 11vh;
}

.image1 {
  width: 4vw;
  height: 8vh;
}

.image2 {
  width: 4vw;
  height: 8vh;
}

.image3 {
  width: 4vw;
  height: 8vh;
}
.dashboard_value_header_row {
  display: flex;
  gap: 20px;
}

.dashboard_value_header_column {
  border-radius: 8px;
  padding: 20px 5px 5px 30px;
  background-color: #202022;
  width: 250px;
  height: 120px;
  display: flex;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.dashboard_value_header_column:hover {
  box-shadow: 0px 0px 5px 0px #00000036;

  transition: 0.3s;

  /* cursor: pointer; */

  transform: scale(1.05);
}

.dashboard_value_header_column p:nth-child(1) {
  font-size: 12px;
  color: gray;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1px;
}

.dashboard_value_header_column p:nth-child(2) {
  font-size: 15px;
  text-align: end;
}

.dashboard_referal_code_row {
  margin: 10px 0;
  max-width: 850px;
}

.dashboard_referal_code_head {
  font-weight: var(--font-semiBold);
  font-size: 11px;
  text-transform: uppercase;
  color: var(--grey-color);
  word-spacing: 2px;
}

.dashboard_referal_code_link {
  position: relative;
  border-radius: 10px;
  padding: 9px;
  color: white;
  margin: 7px 0;
  display: flex;
  align-items: center;
  gap: 10px;
  overflow: hidden;
}

.dashboard_referal_code_link span {
  font-size: 14px;
}

.dashboard_referal_code_social_row {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
}

.share_btns {
  display: flex;
  /* margin-right: 2px; */
}

.share_btns div {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 5px;
  width: 25px;
  height: 25px;
  font-size: 25px;
  border-radius: 20px;
  color: #fff;
  cursor: pointer;
  position: relative;
}

.share_btns div:hover {
  transform: scale(1.1);
  transition: 0.3s;
}

.share_btns div:nth-child(1) {
  /* background-color: #3b5998; */

  background-color: #0165e1;
}

.share_btns div:nth-child(2) {
  /* background-color: #007fb1; */

  background-color: #2c7fd1;
}
.share_btns div:nth-child(3) {
  /* background-color: #00aced; */

  background-color: #1d9bf0;
}
.share_btns div:nth-child(4) {
  /* background-color: #25d366; */

  background-color: #02dc77;
}

.bi-facebook,
.bi-linkedin,
.bi-twitter,
.bi-whatsapp {
  color: #ffffff00;
  width: 20px;
  height: 20px;
  display: flex;
}

.share_btns p {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  color: #fff;
}

.referal_link_copy_icon {
  position: relative;
  cursor: pointer;
  font-size: 25px;
}
.copied_message {
  position: absolute;
  right: 40px;
  bottom: 20px;
  background-color: black;
  padding: 6px 0;
  color: white;
  font-size: var(--fontSize-x-small);
  font-weight: 500;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: mymove 2s;
  animation-iteration-count: 1;
  width: 100px;
  height: 25px;
  text-align: center;
  box-shadow: 0px 0px 3px #6e6e6e;
}

.copied_message span {
  padding-left: 5px;
  color: var(--primary-color);
}

.blurred-background {
  filter: blur(-20px); /* Adjust the blur strength as needed */
}

@keyframes mymove {
  0% {
    top: 60px;
  }
  30% {
    top: 10px;
  }
  70% {
    top: 10px;
  }
  100% {
    top: 60px;
  }
}

.no_data_found_message_on_table {
  text-align: center;
  background-color: #25d366;
  width: 100%;
}

.dashboard_chart {
  max-width: 700px;
  background-color: #ffffff !important;
  box-shadow: 0px 0px 2px 0px #00000036;
  border-radius: 10px;
  /* padding: 5px; */
  /* overflow: auto; */
  margin-bottom: 1rem;
}

.responsive_last_div{
  width: 54vw;
  margin-top: 1rem;
}

@media (max-width: 576px) {
  .dashboard_value_header_column {
    width: 45%;
    height: 115px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin: 30px 80px;
    gap: 10px;
  }
  .dashboard_value_header_row {
    flex-wrap: wrap;
  }

  .dashboard_value_header_column_divide {
    margin: 5px 0;
  }

  .dashboard_value_header_column_divide:nth-child(4) {
    display: none;
  }
  .dashboard_value_header_column p:nth-child(1) {
    font-size: var(--fontSize-xx-small);
    margin-top: -2rem;
  }
  .dashboard_value_header_column p:nth-child(2) {
    font-size: var(--fontSize-small);
  }
  .dashboard_referal_code_link {
    padding: 10px;
    margin: 10px 0;
    gap: 5px;
    word-break: break-all;
  }

  .referal_link_copy_icon {
    font-size: 35px;
  }
  .dashboard_referal_code_head {
    font-size: var(--fontSize-xx-small);
    word-spacing: 1px;
  }
  .share_btns div {
    margin: 0px 3px;
    width: 30px;
    height: 30px;
    font-size: 15px;
  }
  .share_btns p {
    width: 20px;
    height: 20px;
  }
  .bit_coin_img {
    width: 30vw;
    height: 20vw;
  }
  .graph_refral {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  .image_coin {
    width: 40vw;
    height: 25vh;
  }
  .coin_image {
    margin-left: 3rem;
  }

  .dashboard_referal_code_row {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 1rem;
  }
  .green_gradient {
    margin-left: 2rem;
  }
  .table_available_balnc {
    display: flex;
    flex-direction: column;
  }

  .available_balance_coin {
    margin-left: 5rem;
    width: 55vw;
  }
  .web_table_box {
    overflow: none;
  }
  .responsive_last_div {
    width: 100vw;
  }
  .pagination-buttons-container {
    width: 100vw;
  }
  .dj_image{
    width: 50vw;
  }
 
}

.available_balance_coin {
  /* width: 18vw; */
  /* height: 30vh; */
  background-color: #202022;
  margin-top: 4rem;
  padding: 10px;
  margin-right: 2rem;
  text-align: center;
}

.dj_image {
  /* width: 17vw; */
  height: 30vh;
}
.available_balance{
  /* width: 90vw; */
  height: 60vh;
}
