.kyc_submitted_outer_box {
  position: absolute;
  left: 0;
  top: 0;
  width: calc(100% - 140px);
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.153);
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 140px;
}

.kyc_submitted_inner_box {
  width: 600px;
  height: 200px;
  background-color: #a3ec26;
  border-radius: 20px;
  box-shadow: 0 0 2px 1px #00000022;
  font-size: var(--fontSize-medium);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: var(--font-medium);
  line-height: 45px;
  padding: 0 50px;
  color: white;
}

@media (max-width: 768px) {
  .kyc_submitted_outer_box {
    width: 100% !important;
    padding: 0;
  }

  .kyc_submitted_inner_box {
    width: 80%;
    padding: 10px;
  }
}
