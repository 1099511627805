.input_convert label {
  color: white;
}

.input_convert {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
}

.input_convert input {
  width: 30vw;
  height: 5vh;
  border-radius: 5px;
  margin-top: 10px;
}

.input_convert P {
  color: white;
}

.input_convert_AMOUNT {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
}

.input_convert_AMOUNT input {
  width: 30vw;
  height: 5vh;
  border-radius: 5px;
  margin-top: 10px;
}

.input_convert_AMOUNT label {
  color: white;
}

.withdrawl_btnn {
  background-color: #a3ec26;
  color: white;
  /* width: 10vw; */
  padding: 10px;
  border-radius: 8px;
  cursor: pointer;
  margin-top: 2rem;
  display: flex;
 
}

@media (max-width: 550) {
  .st-token-container.mobile {
    display: flex;
    flex-direction: column;
  }

  .st-token-container.mobile .content-wrapper {
    display: flex;
    flex-direction: column;
  }

  .st-token-container.mobile .form-container,
  .st-token-container.mobile .RefralLinks {
    width: 100%;
  }

  .st-token-container.mobile .balance_history {
    text-align: center;
  }

  .st-token-container.mobile .balance_history h4 {
    font-size: 1rem;
  }

  .st-token-container.mobile .RefralLinks {
    margin-left: 0;
    margin-top: 2rem;
  }
  .st-token-container.mobile .withdrawl_btnn {
    width: 20vw;
    height: 5vh;
    margin-left: 6rem;
}

.withdrawl_btnn{
  display: flex;
  justify-content: center;
  align-items: center;
}

}
