@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
}
@-webkit-keyframes autofill {
  0%,
  100% {
    color: #666;
    background: transparent;
  }
}

input:-webkit-autofill {
  -webkit-animation-delay: 1s; /* Safari support - any positive time runs instantly */
  -webkit-animation-name: autofill;
  -webkit-animation-fill-mode: both;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  /* border: 1px solid ; */
  -webkit-text-fill-color: white;
  -webkit-box-shadow: 0 0 0 1000px #373737 inset !important;
  /* transition: background-color 5000s ease-in-out 0s; */
}
.set_table_container {
  display: flex;
  gap: 10px;
  margin-bottom: 1rem;
  background-color: white;
  width: max-content;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 0 10px 0px #0000001a;
}

.set_table_container p {
  cursor: pointer;
  margin: 0;
}
