.otp_input_row {
  display: flex;
  gap: 10px;
  width: 100%;
}

.otp_input_row input {
  width: 40px;
  height: 40px;
  background-color: var(--light-color);
  outline: none;
  border: none;
  border-radius: 5px;
  text-align: center;
  font-size: var(--fontSize-x-small);
  color: var(--grey-color);
}

.otp_input_row input:focus {
  border-bottom: 2px solid var(--primary-color);
  border-radius: 0px;
}

.otp_input_row button {
  width: 40px;
  height: 42px;
  background-color: var(--primary-color);
  outline: none;
  border: none;
  border-radius: 5px;
  text-align: center;
  font-size: var(--fontSize-x-small);
  color: var(--grey-color);
  color: white;
  cursor: pointer;
}
